import React, { Component } from 'react';
import { useSelector } from "react-redux";
import MultiPageTemplate from '../../components/MultiPagePDFReader/index';
import DownloadablePDF from '../../components/downloadable_pdf';
import VideoPlayer from '../../components/VideoPlayer';
import Links from '../../components/Links';
import Session from '../../components/inClassSessions';
import { updateReadingTracker } from './redux_actions';
import Quiz from '../../components/Quiz';
const duringLoad = <div className='loading-screen'>Loading...</div>

let Unit = (props) => {
  let { resourceId, sessionId, toolId } = props.match.params;
  let app = useSelector((state) => state.app);
  let auth = useSelector((state) => state.auth);
  let currentEngagementSessionId = app.currentEngagementSessionId;
  // updateReadingTracker({uid, groupId, resourceId, instanceId: Date.now()}); 
  let uid = auth.user_uid;
  let unit = app.units[resourceId];
  if (unit) {
    return serveTemplate(unit, resourceId, uid, sessionId, toolId, currentEngagementSessionId)
  }
  return <div>No Such Book exists</div>
}

function serveTemplate(unit, resourceId, uid, sessionId, toolId, currentEngagementSessionId) {
  let src = unit.src, docRatio = unit.docRatio || null;

  if (unit.type == 'pdf_downloadable') {
    return <DownloadablePDF src={src} updateReadingTracker={updateReadingTracker} resourceId={resourceId} uid={uid} />
  } else if (unit.type == 'external_video') {
    return <div style={{ width: '100%', height: '100%' }}>
      <VideoPlayer mediaUrl={src} updateReadingTracker={updateReadingTracker} resourceId={resourceId} uid={uid} />
    </div>
  } else if (unit.type == 'links') {
    return <Links unit={unit} updateReadingTracker={updateReadingTracker} resourceId={resourceId} uid={uid} />
  } else if (unit.type == 'inClassSession') {
    return unit.toolId ? <Session unit={unit} toolId={unit.toolId} resourceId={resourceId} uid={uid} /> : <div></div>
  } else if (unit.type == 'quiz') {
    return <Quiz sessionId={sessionId} resourceId={resourceId} toolId={toolId} currentEngagementSessionId={currentEngagementSessionId} />
  }
  else {
    return <div id='_book_meta_cntr' className='_book_meta_cntr'>
      <MultiPageTemplate src={src} updateReadingTracker={updateReadingTracker} duringLoad={duringLoad} docRatio={docRatio} resourceId={resourceId} uid={uid} />
    </div>
  }
}

export default Unit;