import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { chkAccessCode } from '../../containers/app/redux_actions';


let OTP = (props) => {
  const [otp, setOTP] = useState(null);
  const [accessDeniedMsg, setAccessDeniedMsg] = useState(null);
  
  let verifyCode = async (code) => {
    let val_ = await chkAccessCode({uid:props.uid, moduleId:'null', code})
    let val = await JSON.parse(val_);
    if (val.success) {
      let { coachId, engagementId, engagementSessionId, sessionId } = val.msg;
      props.sethasAccess({ value: true, sessionId, engagementSessionId })
    } else {
      setAccessDeniedMsg('Invalid Access Code');
      window.setTimeout(() => {
        setOTP(null);
        setAccessDeniedMsg(null)
      }, 3000);
    }
  }
  return <div style={{ display: 'flex', height: '100vh' }}><div style={{ display: 'flex', flexDirection: 'column', width: 300, margin: 'auto', border: '1px solid #999', borderRadius: 8, padding: 24 }}>
    <div style={{ textAlign: 'center' }}>Please provide an OTP to connect with a LIVE Session</div>
    <TextField
      style={{ marginTop: 16, marginBottom: 16 }}
      id="otp"
      label="otp"
      onChange={(ev) => setOTP(ev.target.value)}
      value={otp ? otp : ''}
    // onClick={(ev) => setOTP(ev.target.value)}
    />
    <Button
      variant="contained"
      color="primary"
      disableElevation
      onClick={() => { verifyCode(otp) }}>
      Submit
    </Button>
    <div style={{ height: 30, fontSize: 12, lineHeight: '30px', textAlign: 'center', color: '#F00' }}>{accessDeniedMsg}</div>
  </div></div>
}

export default OTP;