import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>True innovation is rarely the result of one breakthrough idea from one single person, instead innovative ideas can emerge from just about anywhere. The important part is to recognize and accept new ideas from varied sources.  The best, most sustainable ideas are usually the result of collaborations and collective intelligence. </p>
    <p>In the articles below, you will read more about this connection between collaboration and innovation.</p>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.forbes.com/sites/katevitasek/2015/01/13/innovation-and-collaboration-its-not-an-either-or-proposition/?sh=16bae0d879be' />
      <ExternalResource url='https://www.business-standard.com/article/management/book-extract-a-successful-launch-story-114072700631_1.html' />
      <ExternalResource url='https://www.inc.com/jeff-haden/why-trying-to-inspire-your-team-with-breakthrough-thinking-doesnt-work-roger-bannister-myth-of-4-minute-mile.html' />
    </div>
    <div className='highlighted-patch'>
      <div className='f15_b'>Optional Links</div>
      <ExternalResource url='https://knowledge.insead.edu/leadership-organisations/the-kinds-of-collaboration-that-lead-to-successful-innovation-12991' />
      <ExternalResource url='https://www.pwc.com/gx/en/ceo-agenda/pwc-at-davos/blogs/2017/collaboration-for-innovation-why-technology-alone-isnt-enough.html' />
    </div>
  </div>
}

export default unit;