const INITIAL_STATE = {
  units: null,
  userAccount: null,
  myModules: null,
  userId: null,
  currentModule: 0,
  tabIndex: 0,
  toolData: {},
  sessionData: {},
  currentSession: null,
  currentEngagementSessionId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "RESET_APP":
      return { ...INITIAL_STATE };
    case "SET_CURRENT_MODULE":
      return { ...state, currentModule: action.payload }
    case "UPDATE_USERID":
      return { ...state, userId: action.data };
    case "UPDATE_USER_ACCOUNT":
      return { ...state, userAccount: action.data };
    case "UPDATE_MODULES":
      if (!action.data) { return { ...state, myModules: null } }
      return { ...state, myModules: { ...state.myModules, [action.data.id]: action.data.detail } };
    case "UPDATE_UNITS":
      console.log(action.data);
      return { ...state, units: { ...state.units, [action.data.id]: action.data.detail } };
    case "CURRENT_MODULE":
      return { ...state, units: { ...state.units, [action.data.id]: action.data.detail } };
    case "TAB_INDEX_CHANGE":
      return { ...state, tabIndex: action.value }
    case "UPDATE_TOOLDATA":
      return { ...state, toolData: { ...state.toolData, [action.data.toolId]: action.data.toolData } }
    case "UPDATE_SESSIONDATA":
      return { ...state, currentSession: action.data.sessionId, sessionData: { ...state.sessionData, [action.data.sessionId]: action.data.sessionData } }
    case "UPDATE_ENGAGEMENT_SESSION_ID":
      return { ...state, currentEngagementSessionId: action.data }
    case "UPDATE_TRACKER":
      return { ...state }
    case "RESET_SESSION":
      return { ...state, currentSession: null, currentEngagementSessionId: null }
    default:
      return state;
  }
};
