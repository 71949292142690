import React, { useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import { fetchToolData, updateUserEngagement, connectSessionData } from '../../containers/app/redux_actions';
const { innerWidth: WinWidth, innerHeight: WinHeight } = window;
let slideHeight, slideWidth;
slideHeight = WinHeight - 48 - 48;
slideWidth = WinWidth - 48;

let Quiz = (props) => {
  const uid = props.uid;
  let { resourceId, sessionId, toolId } = props.match.params;
  let session = useSelector((state) => state.app.sessionData[sessionId]);
  let responses = session.sessionEngagements && session.sessionEngagements[toolId]
    ? session.sessionEngagements[toolId]
    : {};
  const [quizData, updateQuizData] = useState({});
  const [index, setIndex] = useState(0);

  let introduction = () => {
    if (quizData && quizData.introduction) {
      return <div>
        <div style={{ textAlign: 'center', marginBottom: 16, fontSize: 18, color: '#FFBC36' }}>
          {quizData.title}
        </div>
        <div>About the Test</div>
        <ol style={{ marginLeft: 16 }}>{quizData.introduction.map((a, i) => {
          return <li key={`intro-${i}`}>{a}</li>
        })}</ol></div>
    } else {
      return null
    }
  }

  const slides = [
    { id: 0, obj: introduction() },
  ];

  const handleChange = ({ qId, oId }) => {
    updateUserEngagement({ sessionId, uid, key: `sessionEngagements`, value: { [toolId]: { ...responses, [qId]: oId } } })
  };

  useEffect(() => {
    let isBeingUpdated = true;
    connectSessionData({ uid, sessionId });
    fetchToolData(toolId)
      .then(a => {
        if (isBeingUpdated) { updateQuizData(a) }
      });
    return () => isBeingUpdated = false;
  }, []);

  Object.keys(quizData.questions || {}).forEach((k, i) => {
    let el = quizData.questions[k];

    let A = <div key={k}>
      <div style={{ textAlign: 'center', marginBottom: 16, fontSize: 14, color: '#FFBC36' }}>{`${i + 1} of ${Object.keys(quizData.questions).length}`}</div>
      <div>{el.text}</div>
      <FormControl component="fieldset">
        <RadioGroup aria-label={k} name={k} value={responses[k] || null} onChange={(ev) => { handleChange({ qId: k, oId: ev.target.value }) }}>
          {Object.keys(el.options).map(ki => {
            let o = el.options[ki];
            return <FormControlLabel key={ki} value={ki} control={<Radio />} label={o.text} />
          })}
        </RadioGroup>
      </FormControl>
    </div>
    slides.push({ id: i + 1, obj: A });
  });

  slides.push({ id: Object.keys(quizData.questions || {}).length, obj: <div>Report turns up here</div> });

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });
  // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);

  const onSlideChange = (dir) => {
    if (dir == 1 && index < slides.length - 1) {
      setIndex(index + 1)
    } else if (dir == -1 && index > 0) {
      setIndex(index - 1)
    }
  }

  return <div style={{ width: WinWidth, height: WinHeight, padding: 36 }}><div>
    <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="slide"
          style={{ ...props, position: 'absolute', width: slideWidth, height: slideHeight }}
        >
          <div style={{ height: '100%' }}>{item.obj}</div>
        </animated.div>
      ))}
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Button color="primary" variant="contained" onClick={() => onSlideChange(-1)}>Pevious</Button>
      <Button color="primary" disabled={index == slides.length - 1} variant="contained" onClick={() => onSlideChange(1)}>{index == slides.length - 2 ? 'Submit' : 'Next'}</Button>
    </div>
  </div>
  </div>
}

export default Quiz;