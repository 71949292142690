import React, { useEffect } from 'react';
import OTP from './otp';
import { Link, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import CloseButton from '@material-ui/icons/Clear';

const sessionEngagements = [
 { type: 'Quiz', title: 'Pre Session Test', id: 't-0001', link: ({sessionId, toolId})=>{return `/app/resource/illumine0001/tool/${toolId}/${sessionId}`} },
 { type: 'TextBlob', title: 'Pre Session Test', id: 't-0002', link: ({sessionId, toolId})=>{return `/app/resource/illumine0001/tool/${toolId}/${sessionId}`} },
 { type: 'Quiz', title: 'Post Session Test', id: 't-0003', link: ({sessionId, toolId})=>{return `/app/resource/illumine0001/tool/${toolId}/${sessionId}`}  }
];

let s = { display: 'block', textDecoration: 'none'}

let Unit = (props) => {
 let { uid, unit } = props;
 let sessionId = useSelector((state) => state.app.currentSession);
 let sessionData = useSelector((state) => sessionId ? state.app.sessionData[sessionId] : {});
 useEffect(() => {
  props.connectSessionData({ uid, sessionId });
 }, []);
 let hasAccess = sessionData.hasAccess;
 return <div>
  <div className='toolbar'
   style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: 16, alignItems: 'center' }} onClick={props.resetSession}>
   Close Session <CloseButton />
  </div>
  {!hasAccess
   ? <OTP
    uid={uid}
    sethasAccess={({ sessionId, value }) => {
    props.connectSessionData({ uid, sessionId });
    }} />
   : <div style={{ padding: 16 }}>
    <div style={{ textAlign: 'center', padding: 16 }}>{unit?unit.title:''}</div>
    {sessionEngagements.map((s, i) => {
     return <Link to={s.link({sessionId, toolId: s.id})||'#'} style={s} key={s.id}><div style={{
      borderTop: '1px solid #bcbcbc',
      padding: '12px 0px',
      display: 'flex'
     }}>
      <div style={{
       borderRadius: 4, backgroundColor: '#E5E5E5',
       width: 24, textAlign: 'center', lineHeight: '30px'
      }}>
       {i + 1}
      </div>
      <div style={{lineHeight: '30px', paddingLeft: 12}}>{s.title}</div>
     </div></Link>
    })}
    {/* <Quiz /> */}
   </div>}
 </div>
}

export default Unit;