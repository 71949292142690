import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>What have workplaces done to ensure innovation is part of the organizational DNA? The articles below will help give you an insight into how these new practises impact innovation for the organization. </p>
    <br />
    <h3>Designing new workplaces for innovations</h3>
    <p>Many a times workplaces put into place policies to help people innovate for the company. For examples Google and 3M decided to give an impetus to innovation in their companies by allocating time for employees to work on passion projects. So, what was the outcome of setting aside time this way? The stories below give insight on how companies and people worked together to ensure the process of innovation leads to positive outcomes. </p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.tlnt.com/this-small-company-is-making-20-time-work/' />
      <ExternalResource url='https://www.fastcompany.com/1663137/how-3m-gave-everyone-days-off-and-created-an-innovation-dynamo' />
      <ExternalResource url='https://mashable.com/2018/05/11/google-20-percent-rule/' />
    </div>
    <br />
    <h3>Building an innovation enabling workplace</h3>
    <p>Below is another personnel initiative from 3M that showcases how they ensure that people will be adequately enabled to give their best to an organization. They have divided these groups of people into three broad categories: Generalists, Specialists and Polymaths. So, while specialists get their own dedicated spaces to work on full time, generalists can join any team they want, and polymaths have access to multiple cross-disciplinary forums for them to participate in. In the end this positively impacts the innovation capabilities of the company.</p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.sciencedirect.com/science/article/pii/S0048733313001881?via%3Dihub' />
    </div>
  </div>
}

export default unit;