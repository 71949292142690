import gtu_701 from './gtu_701';
import gtu_702 from './gtu_702';
import gtu_703 from './gtu_703';
import gtu_704 from './gtu_704';
import gtu_705 from './gtu_705';
import gtu_706 from './gtu_706';
import gtu_707 from './gtu_707';
import gtu_708 from './gtu_708';

import gtu_801 from './Value_Thinking/gtu_801';
import gtu_802 from './Value_Thinking/gtu_802';
import gtu_803 from './Value_Thinking/gtu_803';
import gtu_804 from './Value_Thinking/gtu_804';
import gtu_805 from './Value_Thinking/gtu_805';
import gtu_806 from './Value_Thinking/gtu_806';
import gtu_807 from './Value_Thinking/gtu_807';
import gtu_808 from './Value_Thinking/gtu_808';

import gtu_901 from './Engaging_Deeply/gtu_901';
import gtu_902 from './Engaging_Deeply/gtu_902';
import gtu_903 from './Engaging_Deeply/gtu_903';
import gtu_904 from './Engaging_Deeply/gtu_904';
import gtu_905 from './Engaging_Deeply/gtu_905';
import gtu_906 from './Engaging_Deeply/gtu_906';

import gtu_1001 from './ESI/gtu_1001';
import gtu_1002 from './ESI/gtu_1002';
import gtu_1003 from './ESI/gtu_1003';
import gtu_1004 from './ESI/gtu_1004';
import gtu_1005 from './ESI/gtu_1005';


import bput_101 from './Who_is_a_Contributor/bput_101';
import bput_102 from './Who_is_a_Contributor/bput_102';
import bput_103 from './Who_is_a_Contributor/bput_103';
import bput_104 from './Who_is_a_Contributor/bput_104';
import bput_105 from './Who_is_a_Contributor/bput_105';
import bput_106 from './Who_is_a_Contributor/bput_106';


import bput_201 from './Developing_a_Growth_Identity/bput_201';
import bput_202 from './Developing_a_Growth_Identity/bput_202';
import bput_203 from './Developing_a_Growth_Identity/bput_203';
import bput_204 from './Developing_a_Growth_Identity/bput_204';
import bput_205 from './Developing_a_Growth_Identity/bput_205';
import bput_206 from './Developing_a_Growth_Identity/bput_206';
import bput_207 from './Developing_a_Growth_Identity/bput_207';
import bput_208 from './Developing_a_Growth_Identity/bput_208';
import bput_209 from './Developing_a_Growth_Identity/bput_209';

import bput_301 from './COD/bput_301';
import bput_302 from './COD/bput_302';
import bput_303 from './COD/bput_303';
import bput_304 from './COD/bput_304';
import bput_305 from './COD/bput_305';
import bput_306 from './COD/bput_306';
import bput_307 from './COD/bput_307';
import bput_308 from './COD/bput_308';
import bput_309 from './COD/bput_309';

import bput_401 from './Sustainable_Success/bput_401';
import bput_402 from './Sustainable_Success/bput_402';
import bput_403 from './Sustainable_Success/bput_403';
import bput_404 from './Sustainable_Success/bput_404';
import bput_405 from './Sustainable_Success/bput_405';
import bput_406 from './Sustainable_Success/bput_406';


import bput_501 from './Career_Pathway/bput_501';
import bput_502 from './Career_Pathway/bput_502';
import bput_503 from './Career_Pathway/bput_503';
import bput_504 from './Career_Pathway/bput_504';
import bput_505 from './Career_Pathway/bput_505';
import bput_506 from './Career_Pathway/bput_506';
import bput_507 from './Career_Pathway/bput_507';
import bput_508 from './Career_Pathway/bput_508';

import bput_601 from './Unleashing_Power_to_Contribute/bput_601';
import bput_602 from './Unleashing_Power_to_Contribute/bput_602';
import bput_603 from './Unleashing_Power_to_Contribute/bput_603';
import bput_604 from './Unleashing_Power_to_Contribute/bput_604';
import bput_605 from './Unleashing_Power_to_Contribute/bput_605';
import bput_606 from './Unleashing_Power_to_Contribute/bput_606';
import bput_607 from './Unleashing_Power_to_Contribute/bput_607';
import bput_608 from './Unleashing_Power_to_Contribute/bput_608';


import sch_101 from './Inside_Mind_of_an_Innovator/sch_101';
import sch_102 from './Inside_Mind_of_an_Innovator/sch_102';
import sch_103 from './Inside_Mind_of_an_Innovator/sch_103';
import sch_104 from './Inside_Mind_of_an_Innovator/sch_104';
import sch_105 from './Inside_Mind_of_an_Innovator/sch_105';


export default { gtu_701, gtu_702, gtu_703, gtu_704, gtu_705, gtu_706, gtu_707, gtu_708, gtu_801, gtu_802, gtu_803, gtu_804, gtu_805, gtu_806, gtu_807, gtu_808, gtu_901, gtu_902, gtu_903, gtu_904, gtu_905, gtu_906, bput_101, bput_102, bput_103, bput_104, bput_105, bput_106, bput_201, bput_202, bput_203, bput_204, bput_205, bput_206, bput_207, bput_208, bput_209, bput_301, bput_302, bput_303, bput_304, bput_305, bput_306, bput_307, bput_308, bput_309, gtu_1001, gtu_1002, gtu_1003, gtu_1004, gtu_1005, bput_401, bput_402, bput_403, bput_404, bput_405, bput_406, bput_501, bput_502, bput_503, bput_504, bput_505, bput_506, bput_507, bput_508, bput_601, bput_602, bput_603, bput_604, bput_605, bput_606, bput_607, bput_608, sch_101, sch_102, sch_103, sch_104, sch_105 }