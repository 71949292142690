import React, { useState } from 'react';
import tools from '../../explorations/illumineExperiments';
import { updateUserEngagement, connectSessionData, resetSession } from '../../containers/app/redux_actions';


let Session = (params)=>{
 
 const [ sessionDetails, updateSessionDetails ] = useState(null);
 let toolId = params.toolId, resourceId = params.resourceId, uid = params.uid, unit = params.unit;
 return <div>{tools[toolId]({uid, resourceId, toolId, unit, resetSession, updateUserEngagement, connectSessionData, updateSessionDetails})}</div>
};

export default Session;