import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Expertise does not necessarily lead to innovation – this may seem like a controversial statement, but if you take a step back you realize that being an expert is different from being an innovator. Expertise may serve an important purpose; it may give you the depth needed to drive a solution or to take a thought and turn it into reality. However, what is more important is the acknowledgment that your expertise alone will not give you all the answers, you need to be open to seeking out solutions from newer avenues.</p>
    <p>In the video and articles below, you will learn more about the barriers that expertise creates in thinking innovatively and how to be aware of this. </p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=npBqdJPEzyw' />
      <ExternalResource url='https://www.theinovogroup.com/the-role-of-the-expert-in-innovation/' />
    </div>
    <div className='highlighted-patch'>
      <div className='f15_b'>Long Read</div>
      <ExternalResource url='https://www.sciencedirect.com/science/article/pii/S0048733313001881' />
    </div>
  </div>
}

export default unit;