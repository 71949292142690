import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The following articles will help deepen your own understanding of how innovators develop inner capabilities to solve complex problems and build innovations consistently. </p>
    <br />
    <h3>Cultivating the innovation mindset </h3>
    <p>Cultivating the innovation mindset involves a process of unlearning – you need to unlearn that innovation is not just a process but a behavior trait. The articles below help you think of innovation as a mindset, a way to make sense of the world in a new light. </p>
    <p>These traits involve relishing your failures as fuel to craft your new big idea, getting comfortable with the idea of discomfort, constantly looking for new insights on old problems and adopting the process of defamiliarization. The articles below dwell upon these ideas further. </p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.shiftthework.com/blog/three-keys-to-establishing-the-innovation-mindset' />
      <ExternalResource url='https://www.forbes.com/sites/billfischer/2019/06/09/innovations-changing-mindset-where-are-you/?sh=15d3e7cf5b80' />
      <ExternalResource url='https://hbr.org/2019/04/to-change-the-way-you-think-change-the-way-you-see' />
    </div>
    <br />
    <h3>The relationship between emotional intelligence and innovation</h3>
    <p>Cultivating an innovative mindset requires accepting the fact that you are constantly evolving along with the world around you. This means having a static identity or a fixed approach to solving problems will not serve you or build any new innovations. Emotional intelligence therefore is an important attribute of being an innovator. In the article below you will learn more about the traits needed to develop emotional intelligence</p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.fastcompany.com/3048397/7-habits-of-innovative-thinkers' />
      <ExternalResource url='https://www.washingtonpost.com/news/on-leadership/wp/2014/09/16/how-nasa-keeps-innovating/' />
    </div>
    <br />
    <h3>What are the key characteristics of an innovator?</h3>
    <p>Purpose, passion, and an ability to take risks are some of the characteristics that innovators embody.  In most organizations it is the people that drive innovation, but what drives these people? What do they tell themselves to come with new ideas? The articles below showcase some of the characteristics of an innovator, and also showcase how these characteristics help foster innovation.</p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://hbr.org/2013/10/the-five-characteristics-of-successful-innovators' />
      <ExternalResource url='https://www.forbes.com/sites/sebastianbailey/2012/09/04/what-the-greatest-innovators-tell-themselves-i-control-my-future-because-im-worth-it/?sh=4213e6bb6182' />
      <ExternalResource url='https://dobetter.esade.edu/en/characteristics-innovative-people?_wrapper_format=html' />
      <ExternalResource url='https://knowledge.insead.edu/leadership-organisations/why-some-employees-improve-their-creativity-and-others-dont-16391' />
    </div>
    <div className='highlighted-patch'>
      <div className='f15_b'>Long Read</div>
      <ExternalResource url='https://hbr.org/2009/12/the-innovators-dna' />
    </div>
    <br />
    <h3>What are some of the practises innovators must adopt at work?</h3>
    <p>In the podcast below, Guy Kawasaki, Chief Evangelist of Canva and former Evangelist at Apple advises innovators to ask themselves, how are they going to dent the universe? He also asks innovators to not be afraid of polarising people and to resist naysayers. These are some key practises that innovators need to follow to ensure they stay on course.</p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://guykawasaki.com/remarkable-innovator/' />
      <ExternalResource url='https://www.youtube.com/watch?v=Mtjatz9r-Vc' />
    </div>
  </div>
}

export default unit;