import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>What is one thing great innovators have in common? They think of challenges as opportunities, instead of getting overwhelmed by problems,  they get to the root of the problem and try to think of long-term solutions to these problems. In the articles below you will learn more about solution building and the impact this process of reframing the problem/challenge can have on building innovative solutions.</p>
   
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.cnbc.com/2017/07/05/tesla-elon-musk-and-netflix-reed-hastings-use-this-mental-strategy.html' />
      <ExternalResource url='https://medium.com/seamfix-engineering/first-principles-thinking-the-foundation-of-innovation-5858e002c01b' />
      <ExternalResource url='https://knowledge.insead.edu/leadership-organisations/how-netflix-finds-innovation-on-the-edge-of-chaos-15311' />
    </div>
    <div className='highlighted-patch'>
      <div className='f15_b'>Long Read</div>
      <ExternalResource url='https://hbr.org/2018/09/why-design-thinking-works' />
    </div>
  </div>
}

export default unit;